import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "payment-page"
    }}>{`Payment Page`}</h1>
    <p>{`A payment page is an interface used to enable customers to pay for orders created by customer support or other instances that are able to create orders on behalf of others.`}</p>
    <h2 {...{
      "id": "create-a-payment-page"
    }}>{`Create a payment page`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "/pages-sales-guides#create-order"
          }}>{`Create an order`}</a>{`. It is important to add a customer with a valid email-address to contactInfo to the order, because some payment methods will not work if this is missing from the order. It is also important to add a customer with a valid email-address to the list of travellers in the order. This is to enable tickets to be sent. Without any travellers, the tickets will not be sent.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Get valid payment methods by calling `}<inlineCode parentName="p">{`GET /v1/payment-methods`}</inlineCode>{` with the header `}<inlineCode parentName="p">{`Entur-Distribution-Channel`}</inlineCode>{` set to `}<inlineCode parentName="p">{`ENT:DistributionChannel:PaymentPage`}</inlineCode>{`.`}</p>
        <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>
        {`
            [
                {
                    "clientId": "sS7grDF79fddBFd4=",
                    "distributionChannelId": "ENT:DistributionChannel:PaymentPage",
                    "id": 1,
                    "paymentConfig": {
                        "accountName": "string",
                        "id": 0,
                        "merchantConfig": {},
                        "paymentProvider": "string"
                    },
                    "paymentType": "VISA",
                    "paymentTypeFee": {
                        "amount": "100.00",
                        "currency": "NOK",
                        "name": "ENT:PaymentTypeFee:Collector",
                        "reason": "Collector Invoice",
                        "taxAmount": "25.00",
                        "taxCode": "3",
                        "taxRate": "25.00"
                    },
                    "paymentTypeGroup": "PAYMENTCARD"
                }
            ]
       `}
       </pre>
        </ExpandablePanel>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a payment page by calling `}<inlineCode parentName="p">{`POST /v1/payment-page-orchestrator/payment-page`}</inlineCode>{`. orderId And orderVersion are required fields. expireAt will default to 7 days from now if not set in the request, and paymentMethods will default to all available paymentMethods for DCI=`}<inlineCode parentName="p">{`ENT:DistributionChannel:PaymentPage`}</inlineCode>{` if not set in the request.`}</p>
        <ExpandablePanel title="Example request" mdxType="ExpandablePanel">
    <pre>
        {`
            {
                "orderId": "ABCD1234",
                "orderVersion": 1,
                "paymentMethods": [
                    {
                        "paymentTypeGroup": "PAYMENTCARD",
                        "paymentType": "VISA"
                    }
                ],
                "expiraAt": "2021-03-11T08:43:23.114Z"
            }
       `}
       </pre>
        </ExpandablePanel>
        <ExpandablePanel title="Example response" mdxType="ExpandablePanel">
    <pre>
        {`
            {
                "url": "https://payment-page.dev.entur.org/ABCD1234/685fe9a1-c1b1-4735-98de-c074dfa99d6d",
            }
       `}
       </pre>
        </ExpandablePanel>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Send the link to the customer.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "update-a-payment-page"
    }}>{`Update a payment page`}</h2>
    <p>{`It is possible to update the expiration date and possible payment-methods using the `}<inlineCode parentName="p">{`PATCH /v1/payment-page-orchestrator/payment-page/{id}`}</inlineCode>{`-endpoint.`}</p>
    <pre><code parentName="pre" {...{}}>{`<ExpandablePanel title="Example request">
    <pre>
        {\`
            {
                "paymentMethods": [
                    {
                        "paymentTypeGroup": "PAYMENTCARD",
                        "paymentType": "MASTERCARD"
                    }
                ],
                "expiraAt": "2021-03-13T08:43:23.114Z"
            }
       \`}
       </pre>
</ExpandablePanel>

<ExpandablePanel title="Example response">
    <pre>
        {\`
            {
                "url": "https://payment-page.dev.entur.org/ABCD1234/685fe9a1-c1b1-4735-98de-c074dfa99d6d",
            }
       \`}
       </pre>
</ExpandablePanel>
`}</code></pre>
    <h3 {...{
      "id": "updating-the-order"
    }}>{`Updating the order`}</h3>
    <p>{`If an order that has not been confirmed yet has a payment page, and the order is being updated, payment-page-orchestrator will react to the Kafka-events and update the payment-page accordingly. Per now, this only applies to orders in version 1.`}</p>
    <h2 {...{
      "id": "tickets-and-receipts"
    }}>{`Tickets and receipts`}</h2>
    <p>{`The tickets and receipt will be sent automatically after the payment has been completed, and the order is confirmed.`}</p>
    <h1 {...{
      "id": "payment-page-orchestrator-api-reference"
    }}>{`Payment Page Orchestrator API Reference`}</h1>
    <p><a parentName="p" {...{
        "href": "http://petstore.swagger.io/?url=https://api.entur.io/api-docs/payment-page-orchestrator"
      }}>{`See the API reference on the Swagger Petstore`}</a></p>
    <Swagger url="https://api.entur.io/api-docs/payment-page-orchestrator" baseUrl="https://api.entur.io/sales" mdxType="Swagger" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      